<template>
  <div class="box">
    <div class="search">
      <div class="search-item" v-for="(item, index) in list" :key="index">
        <img :src="item.img ? item.img : '@/assets/img/Home/12.png'" class="search-img" />
        <div class="detail">
          <div class="detail-title">{{ item.title }}</div>
          <div class="detail-type"><span class="detail-type-tips">类型：</span>{{ type[item.type] }}</div>
          <div class="detail-price">￥{{ item.price ? item.price : 0 }}</div>
        </div>
        <el-button class="search-but" type="danger" @click="detail(item)">了解详情</el-button>
      </div>
      <div class="nothing" v-if="!list || list.length == 0">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <el-pagination
        class="tabPagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[8, 16, 24, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getCurriculumsList } from "@/api/home";
import { showTypeEnum } from "@/api/emun";
import Bus from "@/api/bus";
import Vue from "vue";
export default {
  components: {},
  data() {
    return {
      current: 1,
      size: 8,
      total: 0, //总页数
      search: "",
      list: [], //列表
      type: showTypeEnum,
    };
  },
  created() {
    this.search = this.$route.params.search;
    this.getList();
    Bus.$on("search", (val) => {
      this.search = val;
      this.getList();
    });
    this.$forceUpdate();
  },

  methods: {
    getList() {
      if (!this.search) {
        this.list = [];
        this.total = 0;
        return;
      }
      getCurriculumsList(this.size, this.current, undefined, this.search).then((res) => {
        this.list = res.msg.rows;
        this.total = res.msg.total;
      });
    },
    //表格每页个数修改
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    //表格页数修改
    handleCurrentChange(val) {
      this.current = val;
      this.getList();
    },
    //跳转
    detail(row) {
      Vue.prototype.goTypeDetail(row.type, row.id, row.isSkip, row.IsPurchase, undefined, row.productLine);
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  background: #f5f6fa;
  padding: 30px 0px 64px;
}
.search {
  width: 1200px;
  background: #ffffff;
  margin: 0px auto;
  padding: 0px 28px 68px;
  .search-item {
    width: 100%;
    height: 239px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
    .search-img {
      width: 264px;
      height: 167px;
      margin-right: 39px;
      flex-shrink: 0;
    }
    .detail {
      width: 100%;
      height: 167px;
      font-family: Microsoft YaHei;
      .detail-title {
        margin-top: 18px;
        height: 19px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
      }
      .detail-type {
        height: 15px;
        font-size: 16px;
        font-weight: 400;
        color: #f55752;
        margin-top: 20px;
        .detail-type-tips {
          color: #999999;
        }
      }
      .detail-price {
        height: 19px;
        font-size: 24px;
        font-weight: 400;
        color: #f55752;
        margin-top: 44px;
      }
    }
    .search-but {
      flex-shrink: 0;
    }
  }
}
.foxRightCen {
  width: 100%;
  min-height: 580px;
  padding: 180px 0px 20px;
  background: #ffffff;
  .foxRightCenImg {
    width: 214px;
    height: 143px;
    margin: auto;
    margin-bottom: 54px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    margin: auto;
    text-align: center;
    width: auto;
    height: auto;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7b8196;
  }
}
</style>
